@use '../../styles/variables' as v;
.modal{
    --opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    z-index: 999;
    &.show{
        --opacity: 1;
        .modal{
            &_container{
                transform: scale(1);
            }
        }
    }
    &.loading{
        .modal{
            &_container{
                .loading-interface{
                    z-index: 2;
                    visibility: visible;
                    opacity: .75;
                }
            }
        }
    }
    .backdrop{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: v.$light-backdrop;
        opacity: var(--opacity);
        transition: v.$transition-one;
    }
    &_container{
        position: relative;
        background: v.$light-bg-base;
        max-height: 70%;
        display: flex;
        flex-flow: column;
        width: calc(100% - 1rem);
        max-width: 600px;
        margin-bottom: .5rem;
        border-radius: 1rem;
        transition: v.$transition-one;
        opacity: var(--opacity);
        transform: scale(.95);
        overflow: hidden;
        box-shadow: 0 0 15px v.$light-backdrop;
        .loading-interface{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: v.$light-bg-base;
            visibility: hidden;
            opacity: 0;
            z-index: 1;
            transition: v.$transition-one;
        }
        .scroller{
            overflow-y: auto;
            padding: 1.5rem;
        }
        &-title{
            display: flex;
            position: sticky;
            top: -1.5rem;
            z-index: 1;
            margin-top: -1.5rem;
            margin-bottom: .5rem;
            margin-left: -1.5rem;
            margin-right: -1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            background: v.$light-bg-base;
            h2{
                padding-right: 2.25rem;
                flex: 1;
                font-size: 1.25rem;
                font-weight: 500;
                display: flex;
                align-items: center;
            }
            button{
                position: absolute;
                right: .75rem;
                top: 1rem;
            }
        }
        &-content{
            max-height: 100%;
            display: flex;
            flex-flow: column;
            transition: v.$transition-one;
            .image-editor{
                position: relative;
                display: flex;
                .cutter{
                    position: absolute;
                    height: 100%;
                    border: 5px solid v.$light-text-base;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    aspect-ratio: 1 / 1;
                    border-radius: 100%;
                    pointer-events: none;
                }
            }
            .avatar-controls{
                margin-top: 1.5rem;
                display: flex;
                flex-flow: column;
                gap: 1rem;
                .slider{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    span{
                        color:v.$light-text-base;
                    }
                }
                .buttons-group{
                    button{
                        flex: 1;
                        color: v.$light-text-base;
                        border-color: v.$light-text-base;
                        padding: .5rem;
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .modal{
        align-items: flex-end;
        &.show{
            .modal{
                &_container{
                    transform: translate(0%);
                }
            }
        }
        &_container{
            border-radius: 10px 10px 5px 5px;
            transform: translateY(50%);
        }
    }
}