@use '../styles/variables' as v;
.header{
    background: v.$dark-bg-base;
    position: sticky;
    top: 0;
    z-index: 99;
    .superior{
        height: 4rem;
        padding: 0 1.5rem;
        display: flex;
        justify-content: space-between;
        .logo{
            height: 100%;
            color: v.$dark-text-base;
            display: flex;
            align-items: center;
            .brand{
                font-size: 1.75rem;
                font-family: v.$font-branch;
                font-weight: 500;
                margin-right: .3rem;
            }
            .app{
                font-family: v.$font-branch;
                font-size: 1rem;
                margin-top: .7rem;
            }
        }
    }
}
.tabs{
    .MuiTabs-flexContainer {
        width: max-content;
        margin: 0 auto;
    }
    .MuiTabs-indicator{
        background: v.$dark-text-base;
        bottom: 5px;
        border-radius: 10px;
    }
    .tab-link{
        text-transform: initial;
        font-weight: 500;
        font-size: .875rem;
        letter-spacing: 0;
        line-height: 1.25rem;
        hyphens: auto;
        word-break: break-word;
        word-wrap: break-word;
        white-space: nowrap;
        color: v.$dark-text-subdued;
        &.Mui-selected{
            color: v.$dark-text-base;
        }
    }
}