@use '../styles/variables' as v;
.home{
    margin-left: auto;
    margin-right: auto;
    max-width: 840px;
    padding-left: .5rem;
    padding-right: .5rem;
    &-banner{
        padding-top: 1.5rem;
        .user-photo{
            display: flex;
            align-items: center;
            justify-content: center;
            &_layout{
                position: relative;
                .btn{
                    min-width: 0;
                    border-radius: 50%;
                    padding: 0;
                    border: 1px solid v.$light-decorative-subdued;
                    display: block;
                    width: 6rem;
                    height: 6rem;
                    .avatar{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        h1{
            font-size: 1.75rem;
            line-height: 2.25rem;
            letter-spacing: 0;
            font-weight: 400;
            word-break: break-word;
            word-wrap: break-word;
            text-align: center;
            margin-block-start: .67em;
            margin-block-end: .67em
        }
    }
}