@use '../styles/variables' as v;
.section-container{
    width: calc(100% - 1rem);
    max-width: 840px;
    margin: 1rem auto;
}
.section{
    display: flex;
    flex-direction: column;
    border: 1px solid v.$light-decorative-subdued;
    border-radius: 3px;
    margin-top: .5rem;
    .section-header{
        margin-bottom: .5rem;
        padding: 1rem 1rem .5rem 1rem;
        header {
            display: flex;
            > div {
                display: flex;
                flex-direction: column;
                flex: 1 1 0;
                h2 {
                    font-size: 1rem;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 1.5rem;
                    hyphens: auto;
                    word-break: break-word;
                    word-wrap: break-word;
                    color: v.$light-text-highlight;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                > div {
                    padding: .5rem 0 0;
                }
                div {
                    font-size: .875rem;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 1.25rem;
                    word-break: break-word;
                    word-wrap: break-word;
                    color: v.$light-text-subdued;
                    flex-grow: 1;
                    flex-shrink: 1;
                }
            }
        }
    }
}

@media (min-width: 600px) {
    .section{
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 919px) {
    .section{
        margin-bottom: 2.5rem;
    }
}